.loader-overlay {
  background-color: var(--rit-purple);
  display: grid;
  place-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
}

.loader-icon {
  display: grid;
  place-items: center;
}

.rit-logo-loading {
  height: 55px;
  color: var(--rit-green);
  margin-bottom: 50px;
}

.rit-logo {
  height: 55px;
  color: var(--rit-green);
  margin-bottom: 50px;
}

@media (max-width: 797px) {
  .rit-logo {
    height: 35px;
    color: white;
    margin-bottom: 150px;
  }

  .loader-active {
    zoom: 0.7;
  }
}
