.pnf {
  display: grid;
  place-items: center;
  text-align: center;
  height: 100vh;
}

.pnf-gif {
  height: 300px;
}

.pnf-title {
  font-size: 40px;
  color: var(--rit-purple);
  padding: 10px;
}

.pnf-description {
  font-size: 20px;
  color: var(--rit-purple);
  padding: 10px;
}

.pnf-logo {
  margin-top: 50px;
  color: var(--rit-green);
  font-size: 20px;
}

@media (max-width: 767px) {
  .pnf-gif {
    height: 220px;
  }
  .pnf-title {
    font-size: 25px;
    color: var(--rit-purple);
    flex-wrap: wrap;
  }

  .pnf-description {
    font-size: 15px;
    color: var(--rit-purple);
  }
}
