body {
  margin: 0;
  padding: 0;
  font-family: "Isidora";
  --rit-purple: #1b0459;
  --rit-green: #7ceb47;
  --rit-wine: #a4284c;
  background-color: #2b1172;
}

.wrapper {
  padding-inline: 60px;
  margin: 0 auto;
}

.venue-wrapper {
  padding-inline: 200px;
  margin: 0 auto;
}

.small-loader {
  zoom: 0.5;
}

@media (min-width: 2400px) {
  .wrapper {
    max-width: 1450px;
    padding-inline: 60px;
    margin: 0 auto;
  }
}

@media (max-width: 997px) {
  .venue-wrapper {
    padding-inline: 10px;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding-inline: 10px;
  }

  .parent-padding {
    padding: 15px;
  }

  .venue-wrapper {
    padding-inline: 10px;
    margin: 0 auto;
  }
}

.page {
  background-color: white;
}

.rit-blue {
  color: var(--rit-purple);
}

@font-face {
  font-family: "Isidora";
  src: local("Isidora"),
    url("./assets/fonts/Isidora/IsidoraSoft-Medium.ttf") format("truetype");
}

.isidora {
  font-family: "Isidora";
}

@font-face {
  font-family: "Circular-Std";
  src: local("Circular-Std"),
    url("./assets/fonts/circular-std/circular-std-medium-500.ttf")
      format("truetype");
}

.circular-std {
  font-family: "Circular-Std";
}

@font-face {
  font-family: "Isidora-Semi-Bold";
  src: local("Isidora"),
    url("./assets/fonts/Isidora/IsidoraSoft-SemiBold.ttf") format("truetype");
}

.isidora-semi-bold {
  font-family: "Isidora-Semi-Bold";
}

@font-face {
  font-family: "Isidora-Bold";
  src: local("Isidora"),
    url("./assets/fonts/Isidora/Isidora-Alt-Black.otf") format("truetype");
}

.isidora-bold {
  font-family: "Isidora-Bold";
}

/* Neue Montreal */
@font-face {
  font-family: "Neue-Montreal";
  src: local("Neue-Montreal"),
    url("./assets/fonts/Isidora/NeueMontreal-Regular.ttf") format("truetype");
}

.neue-montreal {
  font-family: "Neue-Montreal";
}

h5 {
  font-family: "Neue-Montreal";
  margin: 10px;
}

/* Manrope */
@font-face {
  font-family: "Manrope";
  src: local("Manrope"),
    url("./assets/fonts/Isidora/Manrope-Regular.ttf") format("truetype");
}

.manrope {
  font-family: "Manrope";
}

/* Plus Jakarta Sans */
@font-face {
  font-family: "Plus-Jakarta-Sans";
  src: local("Plus-Jakarta-Sans"),
    url("./assets/fonts/Isidora/PlusJakartaSans.ttf") format("truetype");
}

.jakarta {
  font-family: "Plus-Jakarta-Sans";
}

/* Inter */
@font-face {
  font-family: "Inter";
  src: local("Inter"),
    url("./assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
}

.inter {
  font-family: "Inter";
}

/* Satoshi */
@font-face {
  font-family: "Satoshi";
  src: local("Satoshi"),
    url("./assets/fonts/Satoshi/Satoshi-Regular.ttf") format("truetype");
}

.satoshi {
  font-family: "Satoshi";
}

.flex {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-horizontally {
  margin-left: auto;
  margin-right: auto;
}

.flex-wrap {
  flex-wrap: wrap;
}

.space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

/* remove scroll bar from all web pages */
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: 0;
}

.horizontal-scroll {
  display: flex;
  overflow-x: scroll;
}

.scroll::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  scrollbar-width: 0;
}

/* Hide the up and down arrows in number field */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* Remove the scroll behavior */
input[type="number"] {
  overflow: hidden;
}

/* Hide date and time picker icons */
.no-picker-icon::-webkit-calendar-picker-indicator,
.no-picker-icon::-webkit-inner-spin-button,
.no-picker-icon::-webkit-clear-button {
  display: none;
  -webkit-appearance: none;
  appearance: none;
}

#dateInput::-webkit-datetime-edit-fields-wrapper {
  color: transparent;
}

.parent-padding {
  padding: 33px;
}

.horizontal-line {
  margin-top: 20px;
  color: #f5f5f5;
  border: 1px solid #f6f6f6;
}

/* side drawer transitions */

.slide-in-left-enter {
  transform: translateX(-100%);
}

.slide-in-left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-left-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-left-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

ul,
li {
  list-style: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

@media (max-width: 300px) {
  .parent-padding {
    padding: 15px;
  }
}

@media (min-width: 1440px) {
  .wrapper-main {
    width: 1100px;
    margin: 0 auto;
  }

  .wrapper-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 60px;
  }
}
